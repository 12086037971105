import { useCityStore } from '@store/city';
import { useMemo } from 'react';

import { useProducts, useProductsAuth } from '@api/catalog/product';

import { useAuth } from '@context/auth';

import { ITEMS_PER_PRODUCTS_PAGE } from '@scripts/constants';
import { getTotal, getTotalPages } from '@scripts/helpers';

export const useGetProductsList = (
    filters: any = {},
    { enabled = true, enabledAuth = true }: { enabled: boolean; enabledAuth: boolean }
) => {
    const { isAuthUser } = useAuth();
    const currentCity = useCityStore(({ currentCity }) => currentCity);
    const {
        data: apiProductsList,
        isLoading: isLoadingProducts,
        isFetching: isFetchingProducts,
    } = useProducts(
        { ...filters, ...(currentCity?.region_guid && { customer_region_guid: currentCity.region_guid }) },
        enabled
    );

    const {
        data: apiProductsAuthList,
        isLoading: isLoadingProductsAuth,
        isFetching: isFetchingProductsAuth,
    } = useProductsAuth(
        { ...filters, ...(currentCity?.region_guid && { customer_region_guid: currentCity.region_guid }) },
        enabledAuth
    );

    const productsList = useMemo(
        () => (isAuthUser ? apiProductsAuthList?.data : apiProductsList?.data),
        [isAuthUser, apiProductsList, apiProductsAuthList]
    );

    const totalPages = useMemo(
        () => getTotalPages(isAuthUser ? apiProductsAuthList : apiProductsList, ITEMS_PER_PRODUCTS_PAGE),
        [isAuthUser, apiProductsList, apiProductsAuthList]
    );
    const total = useMemo(
        () => getTotal(isAuthUser ? apiProductsAuthList : apiProductsList),
        [isAuthUser, apiProductsList, apiProductsAuthList]
    );

    return {
        productsList,
        totalPages,
        isLoadingProducts,
        isLoadingProductsAuth,
        total,
        isFetchingProducts,
        isFetchingProductsAuth,
    };
};
