import { Dispatch, ReactNode, ReactNodeArray, createContext, useContext, useMemo, useReducer } from 'react';

export interface TokenDataProps {
    accessToken?: string;
    hasRefreshToken?: boolean;
    expiresAt?: string;
}

export interface AuthContextProps {
    tokenData: TokenDataProps;
    setTokenData: Dispatch<TokenDataProps>;
    isAuthUser?: boolean;
}

const AuthContext = createContext<AuthContextProps | null>(null);
AuthContext.displayName = 'AuthContext';

export const AuthProvider = ({
    children,
    state: stateProps,
}: {
    children: ReactNode | ReactNodeArray;
    state: TokenDataProps;
}) => {
    const updateTokenData = (state: TokenDataProps, action: TokenDataProps) => ({
        ...state,
        ...action,
    });
    const [tokenData, setTokenData] = useReducer(updateTokenData, {
        accessToken: stateProps?.accessToken,
        hasRefreshToken: stateProps?.hasRefreshToken,
        expiresAt: stateProps?.expiresAt,
    });

    const isAuthUser = useMemo(() => !!tokenData.accessToken, [tokenData]);

    const value = useMemo(() => ({ isAuthUser, tokenData, setTokenData }), [tokenData]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error(`Hook useAuth must be used within AuthProvider`);
    }

    return context;
};
