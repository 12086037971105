import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { useEffect, useState } from 'react';
import { QueryClient } from 'react-query';

import { ProductDetail, useCategoryDetail, useVirtualCategoryDetail } from '@api/catalog';
import { getProductDetail } from '@api/catalog/product/api';
import { CommonOption, CommonResponse } from '@api/common/types';
import { useContentPages } from '@api/pages/content-page';

import { KOPECKS_IN_ROUBLE, LIMIT_PAGE } from './constants';
import { useAuthApiClient } from '@api/hooks/useAuthApiClient';

import { getCorrectPathname } from '@utils/getCorrectPathname';

export const isTouch = () => 'ontouchstart' in window;

export const toArray = (arg: any) => [].concat(...[arg]);

export const lang = () => document.documentElement.lang;

export const isObject = (item: any) => typeof item === 'object' && !Array.isArray(item) && item !== null;

export const repeat = (value: any, count: number) => new Array(count).fill(value);

export const debounce = (func: any, wait = 300) => {
    let timeout: any;

    // eslint-disable-next-line func-names
    return function (this: any, ...args: any) {
        const next = () => func.apply(this, args);
        clearTimeout(timeout);
        timeout = setTimeout(next, wait);
    };
};

export const isEmptyArray = (arg: any) => Array.isArray(arg) && arg.length === 0;

export const prepareForSelect = (val: string[]) => val.map(i => ({ value: i, label: i }));
export const prepareEnumForSelect = (val: Record<string, string>) =>
    Object.entries(val).map(([k, v]) => ({ value: k, label: v }));

export const prepareForSelectFromObject = (obj: Record<string, string>) =>
    Object.keys(obj).map(k => ({ value: +k, label: obj[k] }));

export const humanize = (str: string) => {
    const fragments = str.split('_');
    const capitalizedFragments = fragments.map(frag => `${frag[0].toUpperCase()}${frag.slice(1)}`);
    return capitalizedFragments.join(' ');
};

export const customFlat = (category: any, level: number, parentId: number) =>
    category?.reduce((acc: any[], cur: any) => {
        if (cur?.children?.length > 0)
            return [
                ...acc,
                { code: cur?.code, name: cur?.name, level, id: cur.id, parentId },
                ...customFlat(cur.children, level + 1, cur.id),
            ];

        return [...acc, { code: cur?.code, name: cur?.name, level, id: cur.id, parentId }];
    }, []);

export const customFlatWithoutChildrens = (category: any) => {
    if (category)
        return category.reduce((acc: any[], cur: any) => {
            if (cur.children.length > 0)
                return [
                    ...acc,
                    { code: cur.code, name: cur.name, level: 0, id: cur.id },
                    ...customFlat(cur.children, 1, cur.id),
                ];
            if (cur.children.length === 0) return [...acc, { code: cur.code, name: cur.name, level: 0, id: cur.id }];

            return [...acc];
        }, []);
    return [];
};

export const randomizeInt = (min: number, max: number) => Math.floor(min + Math.random() * (max + 1 - min));

export const declOfNum = (n: number, titles: string[]) =>
    // eslint-disable-next-line no-nested-ternary
    titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];

export const getCodeFromUrl = (url: string) => {
    const pathArr = url.split('/').filter(Boolean);
    return pathArr[pathArr.length - 1];
};

export const wait = (time = 1000) => new Promise(resolve => setTimeout(() => resolve(true), time));

export const trimString = (s: string, count: number) => (s.length > count ? `${s.substr(0, count)}...` : s);

export const convertPrice = (rub: string | number, penny: string | number) =>
    rub && `${rub.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')},${penny || '00'}`;

export const fromKopecksToRouble = (kopecks: number) => kopecks / KOPECKS_IN_ROUBLE;
export const fromRoubleToKopecks = (rub: number) => Math.floor(rub * KOPECKS_IN_ROUBLE);

export const formatPrice = (value: number, toFixed = 2) =>
    value?.toFixed(value.toFixed(2).slice(-3) === '.00' ? 0 : toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export type Flatten<T> = T extends any[] ? T[number] : T;

export const getTotalPages = (data: { meta?: { pagination?: { total: number } } } = {}, limit = LIMIT_PAGE) =>
    Math.ceil((data?.meta?.pagination?.total || 0) / limit);

export const getTotal = (data: { meta?: { pagination?: { total: number } } } = {}) =>
    data?.meta?.pagination?.total || 0;
export interface INestedRow {
    id: number;
    parent_id?: number | null;
    subRows?: INestedRow[] | [];
    [key: string]: any;
}

export const getNestedData = (data: INestedRow[]): INestedRow[] => {
    const dataObj: any = {};
    const nestedData: INestedRow[] = [];

    data.forEach(row => {
        dataObj[row.id] = row;
        row.subRows = [];
    });

    data.forEach(row => (row.parent_id ? dataObj[row.parent_id].subRows.push(row) : nestedData.push(row)));

    return nestedData;
};

export const getFlatRows = (rows: INestedRow[]): INestedRow[] => {
    const resRows: INestedRow[] = [];

    rows.forEach(row => {
        resRows.push(row);
        if (row?.subRows && row.subRows.length > 0) resRows.push(...getFlatRows(row.subRows));
    });

    return resRows;
};

export const getObjectWithoutEmptyFields = (obj: any) => {
    if (!obj) return obj;

    const keys = Object.keys(obj).filter(key => obj[key] !== null && obj[key] !== '');
    return Object.fromEntries(keys.map(item => [item, obj[item]]));
};

export const getTextValueByBoolean = (value: boolean) => (value === true ? 'Да' : 'Нет');

export const getQueryKeysFromBrackets = (value: string) =>
    value
        .match(/\[.+\]/)
        ?.map(i => i.replace(/[[\]]|\./g, ''))[0]
        .split('/');

/**
 * router next возвращает pathname в виде /path/[param], и query в виде {param: '123'}
 * поэтому getQueryObjectForPathname возвращает те query, которые соответствуют pathname
 */
export const getQueryObjectForPathname = (pathname: string, query: ParsedUrlQuery) => {
    const keys = getQueryKeysFromBrackets(pathname) || [];
    const queryForPage: ParsedUrlQuery = {};

    keys.forEach(key => {
        queryForPage[key] = query[key];
    });

    return queryForPage;
};

export const prepareTelValue = (tel: string) => {
    if (!tel || tel.length < 10) return '';
    const t = tel.split('');
    const l = tel.length - 1;

    return `+7(${t[l - 9]}${t[l - 8]}${t[l - 7]}) ${t[l - 6]}${t[l - 5]}${t[l - 4]}-${t[l - 3]}${t[l - 2]}-${t[l - 1]}${
        t[l]
    }`;
};

export const cleanPhoneValue = (tel: string) => tel.replace(/[()\- ]/g, '');

export const getDate = (date: string | null | undefined, type?: 'start' | 'end') =>
    date
        ? `${type === 'start' ? 'C ' : ''}${type === 'end' ? ' по ' : ''}${new Date(date).toLocaleDateString('ru')}`
        : '';

export const getPeriod = (start: string | null | undefined, end: string | null | undefined) => {
    if (start === end) return getDate(start);
    return `${getDate(start, 'start')}${getDate(end, 'end')}`;
};

export const toISOString = (date: Date) => format(date, 'yyyy-MM-dd');

export const formatDate = (date: Date, dateFormat = 'dd.MM.yyyy HH:mm') => format(date, dateFormat, { locale: ru });

export const isDateString = (date: string) => {
    if (Number.isNaN(+date)) {
        return new Date(date).toString() !== 'Invalid Date';
    }
    return false;
};

export const toSelectItems = (options: CommonOption[] | undefined) =>
    options?.map(o => ({ label: o.name, value: o.id })) || [];

export const getOptionName = (options: CommonOption[] | undefined, idToFind: number | undefined) =>
    (idToFind && options?.find(o => o.id === idToFind)?.name) || '';

export const isNotEmptyObject = (obj: any) => {
    if (typeof obj === 'object') {
        return Object.keys(obj).some(key => typeof obj[key] !== 'undefined');
    }
    throw new Error('Not an object parameter');
};

export const dateCalcMonthRange = (startDate: Date, endDate: Date) => {
    const one_month = 1000 * 60 * 60 * 24 * 29.3;
    const result = Math.ceil((endDate.getTime() - startDate.getTime()) / one_month);
    if (result < 0) {
        return 0;
    }
    if (result === 0) {
        return 'недавно';
    }
    return `${result === 1 ? '' : result} ${declOfNum(result, ['месяц назад', 'месяца назад', 'месяцев назад'])}`;
};

export const getHost = (): string => {
    const { publicRuntimeConfig } = getConfig();

    if (publicRuntimeConfig?.environment === 'localhost' && typeof window !== 'undefined') {
        return '';
    }

    const host = publicRuntimeConfig?.apiHost;
    if (!host) {
        return '';
    }

    if (host.slice(-1) === '/') {
        return host.slice(0, host.length - 1);
    }
    
    return host;
};

export function choosingType(path: string) {
    const pathname = getCorrectPathname(path);

    switch (true) {
        case /^\/$/.test(pathname):
            return 'main_page';

        case /^\/catalog\/collections\/[a-z_0-9]+$/i.test(pathname):
            return 'virtual_categories';

        case pathname.includes('catalog/reference-cards'):
            return 'reference_card';

        case /^\/product\/[\w-]+$/i.test(pathname):
            return 'pdp';

        case /^\/pages\/[a-z_]+$/.test(pathname):
            return 'page';

        case /^\/brands\/[\w-]+$/i.test(pathname):
            return 'brand_detail';

        case /^\/catalog\/[a-z_0-9]+$/i.test(pathname):
            return 'catalog';

        default:
            return 'other';
    }
}
//@ts-ignore
//ToDo решение временное, до переработки работы корзины
export const priceCalculator = (discount: any, productItem: ProductDetail, qty: number) =>
    productItem.base_price && discount.value_type === 1
        ? productItem.base_price?.price * qty - productItem.base_price?.price * qty * (discount.value / 100)
        : discount.value_type === 2 && productItem.base_price && productItem.base_price.price * qty >= discount.value
        ? productItem.base_price?.price * qty - discount.value
        : 0;
export const priceDiscountCalculator = (productItem: ProductDetail, qty: number) =>
    productItem?.discounts &&
    productItem.discounts.map(discount =>
        (discount.conditions as Array<any>).map((condition: any) =>
            !productItem.price && productItem?.base_price?.price && condition.type === 5
                ? condition.condition.count <= qty
                    ? priceCalculator(discount, productItem, qty)
                    : productItem.base_price?.price
                : condition.type === 10
                ? productItem.base_price &&
                  productItem.base_price.price &&
                  condition.condition <= productItem.base_price.price * qty
                    ? priceCalculator(discount, productItem, qty)
                    : productItem.base_price?.price
                : productItem.base_price?.price
        )
    );
export const getIdBySeoType = (path: string, query: any) => {
    const pageType = choosingType(path);
    const pathname = getCorrectPathname(path);
    const categoryCode = query?.categories ? query?.categories[query?.categories.length - 1] : query.category;

    const { data: categoryDetail } = useCategoryDetail(
        { code: categoryCode },
        ['catalog_level1', 'plp', 'filtered_plp', 'catalog', 'reference_card'].includes(pageType) &&
            Boolean(categoryCode)
    );

    const { data: categoryVirtualDetail } = useVirtualCategoryDetail(
        {
            filter: {
                code: pathname.split('/')[3],
            },
        },
        pageType === 'virtual_categories'
    );

    const { data: contentPageDetail } = useContentPages(
        { filter: { slug: pathname.split('/')[2] } },
        pageType === 'page'
    );

    let productId = 0;
    if (pageType === 'pdp') {
        const product = String(query?.id).split('-');
        productId = Number(product[product.length - 1]);
    }

    switch (true) {
        case ['catalog_level1', 'plp', 'filtered_plp', 'catalog'].includes(pageType):
            return categoryDetail?.data.id;

        case ['virtual_categories'].includes(pageType):
            return categoryVirtualDetail?.data.id;

        case pageType === 'page':
            return contentPageDetail?.data[0]?.id;

        case pageType === 'brand_detail':
            return Number(query?.brand);

        case pageType === 'pdp':
            return productId;

        default:
            return undefined;
    }
};

export const getSeoFilter = (path: string, id?: number) => {
    const pageType = choosingType(path);
    return {
        filter: {
            url: path,
            type: choosingType(path),
            ...(pageType === 'reference_card' && { reference_card_id: id }),
            ...(pageType === 'virtual_categories' && { virtual_category_id: id }),
            ...(pageType === 'brand_detail' && { brand_id: id }),
            ...(pageType === 'pdp' && { offer_id: id }),
            ...(['catalog_level1', 'plp', 'filtered_plp', 'catalog'].includes(pageType) && { category_id: id }),
            ...(pageType === 'page' && { page_id: id }),
        },
    };
};

export const getBooleanValue = (val: boolean) => (val ? 'Да' : 'Нет');

export const changeQtyHelper = (
    value: number,
    user: string | null,
    id: number,
    setProduct: (val: { offer_id: number; qty: number }[]) => void,
    setBasketProducts: (vals: ProductDetail[]) => void,
    basketProducts: ProductDetail[],
    setIsLoadingBasket?: (val: boolean) => void
) => {
    if (user) {
        if (setIsLoadingBasket) setIsLoadingBasket(true);
        setProduct([{ offer_id: id, qty: value }]);
    } else {
        setBasketProducts(basketProducts.map(item => (item.id === id ? { ...item, qty: value } : item)));
    }
};

export const getBreadcrums = (items: { name: string; code: string }[]) =>
    items.map(item => ({ name: item.name, link: `/catalog/${item.code}` }));

export const calcVat = (price: number, vat: number) => (vat * price) / (100 + vat);

export const getItemForMetrics = (product: any) => ({
    item_name: product.name,
    item_id: product.id,
    price: fromKopecksToRouble(
        product.base_price?.price || product.base_price?.cost || product?.price || product?.cost
    ),
    item_brand: product.brand?.name || product.brand_id,
    ...product.category_chain?.reduce(
        (acc: any, cur: { name: string }, index: number) => ({
            ...acc,
            [`item_category${index !== 0 ? index : ''}`]: cur?.name || cur,
        }),
        {}
    ),
});

declare const window: Window & { dataLayer: Record<string, unknown>[] };

export const metricsPushEvent = (action: {
    event?: string;
    ecommerce: any;
    currency?: string;
    shipping?: number;
    transaction_id?: number;
    value?: number;
    tax?: number;
}) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(action);
};

export const metricsSelectItem = (productItem: ProductDetail) => {
    metricsPushEvent({
        event: 'select_item',
        ecommerce: {
            items: [{ ...getItemForMetrics(productItem), quantity: 1 }],
        },
    });
};

export const transformImageUrl = (url: string, w: number, h: number, g?: string) => {
    const { publicRuntimeConfig } = getConfig();

    let host = publicRuntimeConfig?.imgproxyUrl;

    if (!host) {
        return url;
    }

    const newUrl = url && url.split('.ru/')[1];

    if (host.slice(-1) === '/') {
        host = host.slice(0, host.length - 1);
    }

    return `${host}/insecure/${g ? `g:${g}/` : ''}rs:fit:${w}:${h}/plain/file/${newUrl}@webp`;
};

export const getMediaImages = (banner: { mobile_image: string; tablet_image: string; desktop_image: string }) => [
    {
        media: '(max-width: 567px)',
        image: banner.mobile_image,
    },
    {
        media: '(max-width: 767px)',
        image: banner.tablet_image,
    },
    {
        media: '(min-width: 768px)',
        image: banner.desktop_image,
    },
];

export const getProductMaxQuantity = async (id: number) => {
    const queryClient = new QueryClient();

    const apiClient = useAuthApiClient();

    const { key: baseProductKey, fetch: baseProductFetch } = getProductDetail(apiClient)({
        id,
        include: 'brand,images,category,files,seller,attributes',
    });

    const apiProduct: CommonResponse<ProductDetail> = await queryClient.fetchQuery(baseProductKey, baseProductFetch);

    return apiProduct.data.quantity;
};

export const sanitizeId = (uuid: string): string => {
    // Заменяем недопустимые символы на допустимые
    return uuid.replace(/[^a-zA-Z0-9-_]/g, function (match) {
        // Транслитерация недопустимых символов
        const transliterationMap: { [key: string]: string } = {
            ' ': '_',
            ':': '_',
            '.': '_',
            '/': '_',
            '\\': '_',
            '[': '_',
            ']': '_',
            '{': '_',
            '}': '_',
            '(': '_',
            ')': '_',
            '*': '_',
            '&': '_',
            '^': '_',
            '%': '_',
            $: '_',
            '#': '_',
            '@': '_',
            '!': '_',
            '~': '_',
            '`': '_',
            "'": '_',
            '"': '_',
            ';': '_',
            ',': '_',
            '?': '_',
            '|': '_',
            '=': '_',
            '+': '_',
            '<': '_',
            '>': '_',
            А: 'A',
            а: 'a',
            Б: 'B',
            б: 'b',
            В: 'V',
            в: 'v',
            Г: 'G',
            г: 'g',
            Д: 'D',
            д: 'd',
            Е: 'E',
            е: 'e',
            Ё: 'Yo',
            ё: 'yo',
            Ж: 'Zh',
            ж: 'zh',
            З: 'Z',
            з: 'z',
            И: 'I',
            и: 'i',
            Й: 'J',
            й: 'j',
            К: 'K',
            к: 'k',
            Л: 'L',
            л: 'l',
            М: 'M',
            м: 'm',
            Н: 'N',
            н: 'n',
            О: 'O',
            о: 'o',
            П: 'P',
            п: 'p',
            Р: 'R',
            р: 'r',
            С: 'S',
            с: 's',
            Т: 'T',
            т: 't',
            У: 'U',
            у: 'u',
            Ф: 'F',
            ф: 'f',
            Х: 'H',
            х: 'h',
            Ц: 'Ts',
            ц: 'ts',
            Ч: 'Ch',
            ч: 'ch',
            Ш: 'Sh',
            ш: 'sh',
            Щ: 'Sch',
            щ: 'sch',
            Ъ: '',
            ъ: '',
            Ы: 'Y',
            ы: 'y',
            Ь: '',
            ь: '',
            Э: 'E',
            э: 'e',
            Ю: 'Yu',
            ю: 'yu',
            Я: 'Ya',
            я: 'ya',
        };
        return transliterationMap[match] || '_';
    });
};

export function useLoading() {
    const [isLoading, setIsLoading] = useState(true);
    const router = useRouter();

    useEffect(() => {
        router.isReady && setIsLoading(false);
    }, []);

    return isLoading;
}

export const formatPriceMeta = (value: number, toFixed = 2) =>
    value?.toFixed(value.toFixed(2).slice(-3) === '.00' ? 0 : toFixed);
