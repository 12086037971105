import { ProductDetailParams } from '@api/catalog/product/types';
import { CommonResponse, CommonSearchParams, FetchError } from '@api/common/types';
import { useAuthQuery, useBaseQuery } from '@api/hooks';
import { useAuthApiClient } from '@api/hooks/useAuthApiClient';

import { ProductDetail, ProductFilter, ProductsFilterItem } from '../types';
import { getProductDetail, getProductDetailAuth, getProductFilters, getProducts, getProductsAuth } from './api';

export const useProducts = (data: CommonSearchParams<Partial<ProductFilter>>, enabled = true) => {
    const apiClient = useAuthApiClient();
    return useBaseQuery<CommonSearchParams<Partial<ProductFilter>>, CommonResponse<ProductDetail[]>, FetchError>({
        data,
        getMethod: params => getProducts(apiClient)(params),
        enabled,
    });
};

export const useProductsAuth = (data: CommonSearchParams<Partial<ProductFilter>>, enabled = true) => {
    const apiClient = useAuthApiClient();
    return useAuthQuery<CommonSearchParams<Partial<ProductFilter>>, CommonResponse<ProductDetail[]>, FetchError>({
        data,
        getMethod: params => getProductsAuth(apiClient)(params),
        enabled,
    });
};

export const useProductDetail = (data: ProductDetailParams, enabled = true) => {
    const apiClient = useAuthApiClient();
    return useBaseQuery<ProductDetailParams, CommonResponse<ProductDetail>, FetchError>({
        data,
        getMethod: params => getProductDetail(apiClient)(params),
        enabled,
    });
};

export const useProductAuthDetail = (data: ProductDetailParams, enabled = true) => {
    const apiClient = useAuthApiClient();
    return useAuthQuery<ProductDetailParams, CommonResponse<ProductDetail>, FetchError>({
        data,
        getMethod: params => getProductDetailAuth(apiClient)(params),
        enabled,
    });
};

export const useProductsFilters = (data: CommonSearchParams<Partial<ProductFilter>>, enabled = true) => {
    const apiClient = useAuthApiClient();
    return useBaseQuery<CommonSearchParams<Partial<ProductFilter>>, CommonResponse<ProductsFilterItem>, FetchError>({
        data,
        getMethod: params => getProductFilters(apiClient)(params),
        enabled,
    });
};